import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

export function Button(props) {
    const borderList = [
        { 'width': 181, 'border': 'assets/images/sites/brain/button/border-181.png', 'hover': 'assets/images/sites/brain/button/hover-181.png' },
        { 'width': 214, 'border': 'assets/images/sites/brain/button/border-214.png', 'hover': 'assets/images/sites/brain/button/hover-214.png' },
        { 'width': 240, 'border': 'assets/images/sites/brain/button/border-240.png', 'hover': 'assets/images/sites/brain/button/hover-240.png' },
        { 'width': 304, 'border': 'assets/images/sites/brain/button/border-304.png', 'hover': 'assets/images/sites/brain/button/hover-304.png' },
    ];

    const ref = useRef();

    const [hover, setHover] = useState(false);
    const [border, setBorder] = useState(borderList[0]);

    useEffect(() => {
        if (ref.current)
            setBorder(getClosestBorder(ref.current.offsetWidth));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.label]);

    const getClosestBorder = (width) => {
        let list = [];
        for (let i = 0; i < borderList.length; i++)
            list.push(borderList[i].width);

        var closest = list.reduce(function (prev, curr) { return (Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev); });

        for (let i = 0; i < borderList.length; i++)
            if (borderList[i].width === closest)
                return borderList[i];

        return borderList[0];
    }

    return (
        <button className={'relative bg-white ' + (props.className || '')} style={{ minWidth: borderList[0].width, height: 54 }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} disabled={props.disabled} onClick={props.onClick}>

            <motion.img
                className='absolute top-0 w-full h-full'
                src={border.hover}
                alt=''
                transition={{ type: "spring", bounce: 0.6 }}
                initial={'normal'}
                animate={hover ? 'hover' : 'normal'}
                variants={{ hover: { scale: 1, opacity: 1 }, normal: { scale: 0, opacity: 0.0001 } }} />

            <img className='absolute top-0 w-full h-full' src={border.border} alt='' />

            <div ref={ref} className='w-max text-4xl font-bold mx-auto px-8 tracking-wide leading-none opacity-0'>{props.label}</div>
            <div className='absolute top-0 w-full text-4xl font-bold mx-auto px-8 tracking-wide leading-none' style={{ lineHeight: '54px' }}>{props.label}</div>

        </button>
    );
}

export function Modal(props) {
    return (
        <ModalBackground onBackgroundClick={() => props.onClose()}>

            <motion.div
                className={'w-full flex flex-col p-4 max-w-[960px] max-h-screen overflow-hidden'}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}>

                <div className={'flex flex-col flex-1 overflow-hidden bg-white rounded ' + (props.className || '')}>
                    {props.children}
                </div>

            </motion.div>

        </ModalBackground>
    );
}

export function ModalBackground(props) {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='fixed top-0 left-0 z-50 w-full h-full'>
            <div className='relative w-full h-full flex items-center justify-center'>
                <div className='absolute top-0 left-0 w-full h-full bg-opacity-70 bg-white backdrop-filter backdrop-grayscale-100 z-0' onClick={() => props.onBackgroundClick()} />
                <div className='z-10'>
                    {props.children}
                </div>
            </div>
        </motion.div>
    );
}

export function Box(props) {
    return (
        <div className={'w-[163px] h-[163px] relative flex-shrink-0 ' + (props.className || '')}>
            <img className='w-[163px] h-[163px]' src='assets/images/sites/brain/elements/box.png' alt='' />

            <div className='absolute top-[29px] left-[21px] bg-white w-[123px] h-[110px] -rotate-[1deg] p-0.5'>
                {props.children && props.children}
                <div className='w-full h-full bg-cover grayscale rounded' style={{ backgroundImage: 'url(' + props.image + ')' }}></div>
            </div>
        </div>
    );
}