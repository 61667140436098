import References from 'components/common/References';

import { getTranslation } from 'utils/DictionaryUtils';

export default function ClientRow(props) {
    return (
        <div className='relative w-full flex flex-col gap-8 sm:gap-16 items-center pt-16 lg:pt-32 site-px bg-white bg-gradient-to-b from-white via-gray-100 to-white'>

            <div className='flex flex-col gap-2 text-center'>
                <div className='heading'>{getTranslation('www', 'client-title')}</div>
                <div className='sub-heading flex flex-row items-center justify-center'>{getTranslation('www', 'client-sub-title')}<span className='text-[18px]'>&nbsp;😊</span></div>
            </div>

            <References />

        </div>
    );
}