import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper';

export default function References(props) {
    const items = [
        { 'title': 'MySQL', 'url': 'https://www.mysql.com/', 'image': 'assets/images/technologies/mysql.png', },
        { 'title': 'Java Enterprise Edition', 'url': 'https://www.oracle.com/java/technologies/java-ee-glance.html', 'image': 'assets/images/technologies/java-ee.png', },
        { 'title': 'Python', 'url': 'https://www.python.org/', 'image': 'assets/images/technologies/python.png', },
        { 'title': 'TensorFlow', 'url': 'https://www.tensorflow.org/', 'image': 'assets/images/technologies/tensorflow.png', },
        { 'title': 'RabbitMQ', 'url': 'https://www.rabbitmq.com/', 'image': 'assets/images/technologies/rabbitmq.png', },
        { 'title': 'WildFly', 'url': 'https://www.wildfly.org/', 'image': 'assets/images/technologies/wildfly.png', },
        { 'title': 'Keras', 'url': 'https://keras.io/', 'image': 'assets/images/technologies/keras.png', },
        { 'title': 'Microsoft SQL Server', 'url': 'https://www.microsoft.com/en-us/sql-server', 'image': 'assets/images/technologies/microsoft-sql-server.png', },
        { 'title': 'Oracle', 'url': 'https://www.oracle.com/', 'image': 'assets/images/technologies/oracle.png', },
        { 'title': 'Hibernate', 'url': 'https://hibernate.org/', 'image': 'assets/images/technologies/hibernate.png', },
        { 'title': 'Cassandra', 'url': 'https://cassandra.apache.org/', 'image': 'assets/images/technologies/cassandra.png', },
        { 'title': 'Pytorch', 'url': 'https://pytorch.org/', 'image': 'assets/images/technologies/pytorch.png', },
        { 'title': 'GitLab', 'url': 'https://gitlab.com/', 'image': 'assets/images/technologies/gitlab.png', },
    ]

    return (
        <div className='w-full flex flex-col overflow-hidden bg-white'>

            <Swiper
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={65}
                loop={true}
                pagination={{
                    clickable: true,
                    // renderBullet: function (index, className) {
                    //     // return ('<span class='' + className + ''>' + (index + 1) + '</span>');
                    //     return (<div>{index}</div>);
                    // },
                }}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                modules={[Autoplay, Pagination]}
                style={{ width: '100%', overflow: 'visible' }}>
                {items.map((item, index) => (<SwiperSlide key={index} style={{ width: '165px' }}><Item data={item} /></SwiperSlide>))}
            </Swiper>

        </div>
    );
}

function Item(props) {
    return (
        <button href={props.data.url} target='_blank' className='bg-red-4000 w-full h-32 group' alt={props.data.title} rel='noreferrer'>

            <img className='w-full bg-red-4000 grayscale group-hover:grayscale-0 opacity-60 group-hover:opacity-100 transition' src={props.data.image} alt={props.data.title} />

        </button>
    );
}