import { ParallaxProvider } from 'react-scroll-parallax';

import OnePageCMS from 'cms/OnePageCMS.js';

export default function App() {
  return (
    <ParallaxProvider>
      <OnePageCMS siteKey='BRAIN' />
    </ParallaxProvider>
  );
}