import { Box } from 'components/rows/brain/Elements.js';

export default function DomainsRow(props) {
    const items = [
        { 'title': 'Commerce', 'items': ['Targeting', 'Personalization', 'Price Negotiation', 'Online to Offline Commerce', 'Support Automation'], 'image': 'assets/images/sites/brain/domains/ecommerce.jpg' },
        { 'title': 'Finance', 'items': ['Risk Analysis', 'Support Automation', 'Company Knowledge', 'Portfolio Analysis', 'Financial Prediction'], 'image': 'assets/images/sites/brain/domains/finance.jpg' },
        { 'title': 'MedTech', 'items': ['Early Warnings', 'Personalized Medicine', 'Unusual Case Detection', 'Decision Support', 'Medical Document Processing'], 'image': 'assets/images/sites/brain/domains/medtech.jpg' },
        { 'title': 'Industry', 'items': ['Manufacturing Process Optimization', 'Smart Sensors', 'Predictive Maintenance', 'Visual Inspection'], 'image': 'assets/images/sites/brain/domains/industry.jpg' },
        { 'title': 'Online Media', 'items': ['Opinion Mining', 'News Portfolio Analysis', 'Topic Detection', 'Trend Detection'], 'image': 'assets/images/sites/brain/domains/media.jpg' },
        { 'title': 'Tourism', 'items': ['Group Recommendation', 'Hotel and Car Targeting', 'Opinion Mining', 'Target Audience Identification'], 'image': 'assets/images/sites/brain/domains/tourism.jpg' },
    ]

    return (
        <div className='w-full bg-white flex items-center justify-center site-px'>

            <div className='w-full max-w-boxed flex flex-col items-center gap-24'>

                <Heading />

                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 pl-0 sm:pl-16 xl:pl-48'>
                    {items.map((item, index) => (<Item key={index} data={item} index={index} />))}
                </div>
            </div>

        </div>
    );
}

function Heading(props) {
    return (
        <div className='text-[#444242] uppercase leading-none relative self-start text-center ml-[35px]'>

            <div className='text-[40px] sm:text-[50px] font-["Patua_One",cursive] tracking-wider rotate-2'>The domains</div>
            <div className='text-[42px] sm:text-[52px] font-["Amatic_SC",cursive] font-bold text-color-site-primary tracking-wider rotate-3'>we are active in</div>

            <div className='absolute w-[327] h-[137] sm:w-[387px] sm:h-[162px] -left-[35px] top-[10px]'>
                <img className='' src='assets/images/sites/brain/domains/bubble-2.png' alt='' />
            </div>

        </div>
    );
}

function Item(props) {
    return (
        <div className={'flex flex-col gap-6 items-center ' + (props.index % 2 === 0 ? 'rotate-[0.5deg]' : '-rotate-[0.5deg]')}>
            <div className='font-bold text-[38px] sm:text-[28px] xl:text-[38px] text-color-site-primary whitespace-nowrap'>ai in <span className='font-normal font-["Patua_One",cursive] uppercase text-[#444242]'>{props.data.title}</span></div>
            <Box image={props.data.image} />
            <div className='font-["Adamina",serif] text-sm md:text-base leading-7 text-center'>{props.data.items.map((item, index) => (<div key={index}>&bull; {item}</div>))}</div>
        </div>
    );
}