import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
// import { Link, NavLink } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react';
import * as Icons from 'react-icons/fa';

import { IconLink } from 'components/common/Common';
import { getSiteConfig } from 'utils/SiteUtils';
import { getTranslation, storeLanguage } from 'utils/DictionaryUtils';

import globalConfig from 'config/globalConfig.js';

export default function Header(props) {
	const [compact, setCompact] = useState(false);
	const [sliderOpen, setSliderOpen] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			if (window.pageYOffset > 0) setCompact(true);
			else setCompact(false);
		};
		window.removeEventListener('scroll', onScroll);
		window.addEventListener('scroll', onScroll, { passive: true });
		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	return (
		<>
			<div className='fixed top-0 w-full z-50'>
				<header className={(compact ? 'h-16' : 'h-20') + ' transition-all flex flex-row justify-between bg-white drop-shadow-xl rounded-xl m-4 sm:m-6 px-4 sm:px-6 transform-gpu ' + (compact && !sliderOpen ? 'bg-opacity-60 ' : 'bg-opacity-100 ') + (props.className || '')}>

					<button>
						<img className={'transition-all ' + (compact ? (getSiteConfig().logo.small ? 'h-6' : 'h-8') : (getSiteConfig().logo.small ? 'h-7' : 'h-10'))} src={getSiteConfig().logo.image} alt={getSiteConfig().title} />
					</button>

					<div className='hiddenn sm:flex min-h-full gap-8 items-center'>

						{/* <div className='flex flex-row gap-6'>
							{menu.map((item, index) => (<MenuItem key={index} path={item.path} label={item.label} />))}
						</div> */}

						<div className='h-full flex gap-4 sm:gap-6'>
							<DropdownButton label={getTranslation('common', 'contact')} icon={<Icons.FaPhoneAlt />} compact={compact} dropdown={<ContactDropdown compact={compact} />} />
							<DropdownButton label={getTranslation('common', 'brands')} icon={<Icons.FaLightbulb />} compact={compact} dropdown={<BrandsDropdown compact={compact} />} />
							{getSiteConfig().availableLanguages.length > 1 && <DropdownButton label={getTranslation('common', 'language')} icon={<Icons.FaFlag />} compact={compact} dropdown={<LanguageDropdown compact={compact} />} />}
						</div>

					</div>

					{/* <div className='flex sm:hidden items-center justify-center -mr-1.5'>
						<Hamburger size={28} color='#595959' direction='left' toggled={sliderOpen} toggle={setSliderOpen} />
					</div> */}

				</header>
			</div>

			{/* <MobileMenu open={sliderOpen} onBackgroundClick={() => setSliderOpen(false)} onItemClick={() => setSliderOpen(false)} /> */}
		</>
	);
}

// function MenuItem(props) {
// 	return (
// 		<div
// 			className={({ isActive }) => 'text-center transition uppercase ' + (props.className || '') + (isActive ? ' font-extrabold' : '')}
// 			to={props.path}
// 			onClick={() => console.log('hello')}>

// 			{props.label}

// 		</div>
// 	);
// }

// function MobileMenu(props) {
// 	return (
// 		<motion.div
// 			className='fixed w-full min-h-full flex flex-col items-center justify-center backdrop-blur-sm z-40'
// 			initial={props.open ? 'visible' : 'hidden'}
// 			animate={props.open ? 'visible' : 'hidden'}
// 			variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0.9999, transitionEnd: { display: 'none' } } }}>

// 			<motion.div
// 				className='absolute w-full h-full bg-black bg-opacity-10'
// 				initial={props.open ? 'visible' : 'hidden'}
// 				animate={props.open ? 'visible' : 'hidden'}
// 				variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
// 				onClick={() => props.onBackgroundClick()} />

// 			<motion.div
// 				className='bg-white drop-shadow-xl rounded-xl p-6 flex flex-col items-stretch justify-center divide-y'
// 				initial={props.open ? 'visible' : 'hidden'}
// 				animate={props.open ? 'visible' : 'hidden'}
// 				variants={{ visible: { scale: 1, opacity: 1 }, hidden: { scale: 1.25, opacity: 0 } }}>
// 				{menu.map((item, index) => (<MenuItem key={index} className='py-3 first:pt-0 last:pb-0' path={item.path} label={item.label} onClick={() => props.onItemClick()} />))}
// 			</motion.div>

// 		</motion.div>
// 	);
// }

function DropdownButton(props) {
	const [hover, setHover] = useState(false);

	return (
		<div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

			<button className={'h-full w-8 flex flex-col items-center justify-center ' + (hover ? 'text-color-site-primary' : '')} >

				<div className='text-2xl my-2'>{props.icon}</div>

				<motion.div
					className='text-[7px] sm:text-[10px] font-semibold sm:font-normal uppercase leading-none common-header-dropdown-button-label'
					initial={props.compact ? 'hidden' : 'visible'}
					animate={props.compact ? 'hidden' : 'visible'}
					variants={{ visible: { y: 0, opacity: 1, height: 'auto' }, hidden: { y: -10, opacity: 0, height: 0 } }}>{props.label}</motion.div>

			</button>

			<div className='absolute bottom-0 right-0'>
				<div className='relative'>
					<motion.div
						className={'absolute top-0 right-0 ' + (hover ? 'z-50' : '')}
						style={{ originX: 0.9, originY: 0 }}
						initial={hover ? 'visible' : 'hidden'}
						animate={hover ? 'visible' : 'hidden'}
						variants={{ visible: { opacity: 1, display: 'block', scale: 1 }, hidden: { opacity: 0, scale: 0.75, transitionEnd: { display: 'none' } } }}>
						{props.dropdown}
					</motion.div>
				</div>
			</div>

		</div>
	)
}

function Dropdown(props) {
	return (
		<div className='flex flex-col'>
			<div className='w-full h-4' />
			<div className={'bg-white drop-shadow-xl rounded-xl p-4 gap-2 transition-all ' + (props.compact ? 'bg-opacity-60 ' : 'bg-opacity-100 ') + (props.className || '')}>
				{props.children}
			</div>
		</div>
	);
}

function ContactDropdown(props) {
	return (
		<Dropdown compact={props.compact} className='w-80 sm:w-96 flex flex-col text-xs tracking-wide'>

			<div className='flex flex-row items-stretch rounded drop-shadow-md overflow-hidden'>

				<ContactDropdownMapButton />

				<div className='flex-1 p-4 flex flex-col items-start justify-center bg-white common-header-contact-dropdown'>

					<div className='font-semibold uppercase'>{globalConfig.contact.name}</div>
					<div className='mt-0'>{globalConfig.contact.address}</div>

					<IconLink className='mt-1' icon={<Icons.FaGlobeAfrica />} value={globalConfig.contact.web} href={'https://' + globalConfig.contact.web + '/'} />

				</div>

			</div>

			<div className='flex flex-col gap-2'>

				<ContactDropdownData label={getTranslation('common', 'email-address')} value={globalConfig.contact.email} icon={<Icons.FaEnvelope />} href={'mailto:' + globalConfig.contact.email} />
				<ContactDropdownData label={getTranslation('common', 'phone-number')} value={globalConfig.contact.phone} icon={<Icons.FaMobileAlt />} href={'tel:' + globalConfig.contact.phone} />

			</div>

		</Dropdown>
	);
}

function ContactDropdownMapButton(props) {
	const [hover, setHover] = useState(false);

	return (
		<a href='https://www.google.com/maps/place/Budapest,+R%C3%A1k%C3%B3czi+%C3%BAt+19,+1088/@47.495193,19.0625303,17z/data=!3m1!4b1!4m5!3m4!1s0x4741dc5d397dde0d:0xf3eef5168d44cc91!8m2!3d47.495193!4d19.064719'
			target='_blank'
			className='min-w-24 min-h-24 flex flex-col gap-2 items-center justify-center bg-site-primary'
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)} rel='noreferrer'>

			<motion.div
				initial={hover ? 'visible' : 'hidden'}
				animate={hover ? 'visible' : 'hidden'}
				variants={{ visible: { y: 0 }, hidden: { y: 5 } }}>
				<Icons.FaMapMarkerAlt className='text-5xl text-white' />
			</motion.div>

			<motion.div
				className='w-10 h-1 bg-white rounded-full'
				initial={hover ? 'visible' : 'hidden'}
				animate={hover ? 'visible' : 'hidden'}
				variants={{ visible: { width: 35, opacity: 1 }, hidden: { width: 0, opacity: 0 } }} />

		</a>
	);
}

function ContactDropdownData(props) {
	return (
		<a href={props.href} className='flex-1 pl-2.5 pr-2.5 py-2 flex gap-2 items-center justify-between rounded drop-shadow-md bg-white hover:text-white transition bg-site-primary-hover common-header-contact-dropdown-data'>
			<div className='flex gap-2.5 items-center'>
				{props.icon}
				<div className=''>{props.label}</div>
			</div>
			<div className=''>{props.value}</div>
		</a>
	);
}

function BrandsDropdown(props) {
	return (
		<Dropdown compact={props.compact} className='w-[20rem] sm:w-[32rem] grid grid-cols-2 sm:grid-cols-3'>

			{globalConfig.brandList.map((item, index) => (<BrandsDropdownItem key={index} data={item} />))}

		</Dropdown>
	);
}

function BrandsDropdownItem(props) {
	const [hover, setHover] = useState(false);

	return (
		<a href={props.data.url} target='_blank' className='relative aspect-video rounded drop-shadow-md overflow-hidden' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} rel='noreferrer'>

			<motion.div
				className='w-full h-full flex items-center justify-center font-semibold text-xs common-header-brands-dropdown-label'
				initial={hover ? 'visible' : 'hidden'}
				animate={hover ? 'visible' : 'hidden'}
				variants={{ visible: { scale: 1 }, hidden: { scale: 1.25 } }}>
				{props.data.label}
			</motion.div>

			<motion.div
				className='absolute top-0 w-full h-full flex items-center justify-center bg-white rounded'
				// style={{ originX: 0.5, originY: 0.5, transformPerspective: 1250 }}
				// transition={{ duration: 0.35 }}
				initial={hover ? 'visible' : 'hidden'}
				animate={hover ? 'visible' : 'hidden'}
				variants={{ visible: { scale: 0, opacity: 0 }, hidden: { scale: 1, opacity: 1 } }}>

				<img alt={props.label} src={props.data.image} />

			</motion.div>

			{/* <img alt={props.label} src={props.data.image} /> */}

		</a>
	);
}

function LanguageDropdown(props) {
	return (
		<Dropdown compact={props.compact} className='w-[10rem] flex flex-col gap-2'>

			{getSiteConfig().availableLanguages.map((item, index) => (<LanguageDropdownItem key={index} data={item} />))}

		</Dropdown>
	);
}

function LanguageDropdownItem(props) {
	return (
		<button className='w-full h-10 p-2 flex flex-row gap-2 items-center rounded drop-shadow-md bg-white overflow-hidden' onClick={() => storeLanguage(props.data, true)}>

			<img className='h-full' alt={props.data} src={globalConfig.languageConfig[props.data].icon} />
			<div className='flex-1 text-xs font-semiboldd uppercase'>{globalConfig.languageConfig[props.data].label}</div>

		</button>
	);
}