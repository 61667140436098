import Technologies from 'components/common/Technologies';

export default function TechnologyRow(props) {

    return (
        <div className='w-full flex flex-col overflow-hidden bg-white gap-16 pt-32'>

            <div className='flex flex-col gap-2 text-center text-[#444242]'>
                <div className='heading'>Technologies involved</div>
                <div className='sub-heading'>Open source & cluster aware technologies</div>
            </div>

            <Technologies />

        </div>
    );
}