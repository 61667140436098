import References from 'components/common/References';

import { getTranslation } from 'utils/DictionaryUtils';

export default function ClientRow(props) {
    return (
        <div className='relative w-full flex flex-col gap-8 sm:gap-16 items-center bg-[#4fa7d8] py-16 site-px'>

            <div className='flex flex-col gap-2 items-center text-center site-px'>
                <img src='assets/images/creo-dots.svg' alt='Creo Group' className='w-14' />

                <div className='hidden md:block text-4xl font-extrabold uppercase'>{getTranslation('www', 'client-title') + ', ' + getTranslation('www', 'client-sub-title')}</div>

                <div className='flex md:hidden flex-col gap-2 text-center text-slate-900'>
                    <div className='text-4xl font-extrabold uppercase'>{getTranslation('www', 'client-title')}</div>
                    <div className='text-lg font-light'>{getTranslation('www', 'client-sub-title') + ' 😊'}</div>
                </div>

            </div>

            <References />

        </div>
    );
}