import { Helmet } from 'react-helmet';
import 'css/www.css';

export default function Meta(props) {
    return (
        <div className='application'>
            <Helmet>
                <title>Creo Group</title>

                <meta name='keywords' content='creo group, creo, creo.hu, creo.hu kft.' />
                <meta name='description' content='A Te sikered!' />
                <meta name='author' content='Creo - https://creo.hu' />

                <meta property='og:url' content='https://www.creo.hu/' />
                <meta property='og:title' content='Creo Group' />
                <meta property='og:description' content='A Te sikered!' />
                <meta property='og:site_name' content='www.creo.hu' />
                <meta property='og:image' content='https://www.creo.hu/assets/images/sites/og-img.png' />

                <link rel='apple-touch-icon' sizes='57x57' href='assets/images/sites/www/favicon/apple-touch-icon-57x57.png' />
                <link rel='apple-touch-icon' sizes='60x60' href='assets/images/sites/www/favicon/apple-touch-icon-60x60.png' />
                <link rel='apple-touch-icon' sizes='72x72' href='assets/images/sites/www/favicon/apple-touch-icon-72x72.png' />
                <link rel='apple-touch-icon' sizes='76x76' href='assets/images/sites/www/favicon/apple-touch-icon-76x76.png' />
                <link rel='apple-touch-icon' sizes='114x114' href='assets/images/sites/www/favicon/apple-touch-icon-114x114.png' />
                <link rel='apple-touch-icon' sizes='120x120' href='assets/images/sites/www/favicon/apple-touch-icon-120x120.png' />
                <link rel='apple-touch-icon' sizes='144x144' href='assets/images/sites/www/favicon/apple-touch-icon-144x144.png' />
                <link rel='apple-touch-icon' sizes='152x152' href='assets/images/sites/www/favicon/apple-touch-icon-152x152.png' />
                <link rel='apple-touch-icon' sizes='180x180' href='assets/images/sites/www/favicon/apple-touch-icon-180x180.png' />
                <link rel='icon' type='image/png' href='assets/images/sites/www/favicon/favicon-32x32.png' sizes='32x32' />
                <link rel='icon' type='image/png' href='assets/images/sites/www/favicon/android-chrome-192x192.png' sizes='192x192' />
                <link rel='icon' type='image/png' href='assets/images/sites/www/favicon/favicon-96x96.png' sizes='96x96' />
                <link rel='icon' type='image/png' href='assets/images/sites/www/favicon/favicon-16x16.png' sizes='16x16' />
                <link rel='manifest' href='assets/images/sites/www/favicon/manifest.json' />
                <link rel='shortcut icon' href='assets/images/sites/www/favicon/favicon.ico' />
                <meta name='msapplication-TileColor' content='#65b2de' />
                <meta name='msapplication-TileImage' content='assets/images/sites/www/favicon/mstile-144x144.png' />
                <meta name='msapplication-config' content='assets/images/sites/www/favicon/browserconfig.xml' />
                <meta name='theme-color' content='#65b2de' />

                <link rel='preconnect' href='https://fonts.googleapis.com' />
                <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
                <link href='https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap' rel='stylesheet' />

            </Helmet>
        </div>
    );
}