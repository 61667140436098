import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

export default function ContactRow(props) {
    return (
        <div className='w-full bg-white flex items-center justify-center site-px pt-16 sm:pt-32 pb-24 sm:pb-32'>

            <div className='relative w-full max-w-boxed'>

                <div className='relative w-full h-[266px] sm:h-[354px] md:h-[425px] lg:h-[490px]'>
                    <img className='absolute left-0 sm:left-2 lg:left-12 xl:left-32 top-0 w-[200px] sm:w-[320px] md:w-[420px] lg:w-[509px]' src='assets/images/sites/brain/contact/sheeps.png' alt='' />
                    <img className='absolute right-0 sm:right-2 lg:right-12 xl:right-32 top-[125px] w-[127px] sm:w-[202px] md:w-[266px] lg:w-[322px]' src='assets/images/sites/brain/contact/sheep.png' alt='' />
                </div>

                <div className='absolute w-auto bg-red-2000 top-[240px] sm:top-[20px] md:top-[120px] left-[20px] sm:left-[initial] sm:right-0 lg:right-12' >
                    <Button />
                </div>

                {/* top-0   */}
                {/* top-48 sm:top-16 right-0 */}
                {/* pt-24 pb-32 */}
            </div>

        </div>
    );
}

function Button(props) {
    const [hover, setHover] = useState(false);
    return (
        <div
            className={'relative font-["Amatic_SC",cursive] font-bold text-[#444242] text-hover-color-site-primary text-5xl whitespace-nowrap transition hover:rotate-[3deg] ' + (props.className || '')}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <a href='mailto:info@creo.hu'>Contact Us!</a>
            <motion.img
                className='absolute -top-[45px] left-[15px] w-[140px] h-[127px] pointer-events-none'
                src='assets/images/sites/brain/contact/button_background_hover.png'
                alt='Contact Us!'
                transition={{ type: "spring", bounce: 0.6 }}
                initial={hover ? 'visible' : 'hidden'}
                animate={hover ? 'visible' : 'hidden'}
                variants={{ visible: { scale: 1, opacity: 1 }, hidden: { scale: 0, opacity: 0 } }} />

        </div>
    );
}