

import WwwMeta from 'components/rows/www/Meta.js';
import WwwHeroRow from 'components/rows/www/HeroRow.js';
import SliderRow from 'components/rows/www/SliderRow.js';
import WwwClientRow from 'components/rows/www/ClientRow.js';
import WwwContactRow from 'components/rows/www/ContactRow.js';

import BrainMeta from 'components/rows/brain/Meta.js';
import BrainHeroRow from 'components/rows/brain/HeroRow.js';
import DomainsRow from 'components/rows/brain/DomainsRow.js';
import ProductsRow from 'components/rows/brain/ProductsRow.js';
import ServicesRow from 'components/rows/brain/ServicesRow.js';
import TechnologyRow from 'components/rows/brain/TechnologyRow.js';
import BrainClientRow from 'components/rows/brain/ClientRow.js';
import BrainContactRow from 'components/rows/brain/ContactRow.js';

const siteConfig = {
    'WWW': {
        'title': 'creo.hu Kft.',
        'logo': {
            'image': 'assets/images/sites/www/logo.png',
        },
        'availableLanguages': ['hu-HU', 'en-GB'],
        'rowList': [
            { 'id': 'meta', 'component': WwwMeta },
            { 'id': 'hero', 'component': WwwHeroRow },
            { 'id': 'slider', 'component': SliderRow },
            { 'id': 'references', 'component': WwwClientRow },
            { 'id': 'contact', 'component': WwwContactRow },
        ]
    },
    'BRAIN': {
        'title': 'Creo Brain',
        'logo': {
            'image': 'assets/images/sites/brain/logo.svg',
            'small': true
        },
        'availableLanguages': ['en-GB'],
        'rowList': [
            { 'id': 'meta', 'component': BrainMeta },
            { 'id': 'hero', 'component': BrainHeroRow },
            { 'id': 'domains', 'component': DomainsRow },
            { 'id': 'products', 'component': ProductsRow },
            { 'id': 'services', 'component': ServicesRow },
            { 'id': 'technology', 'component': TechnologyRow },
            { 'id': 'references', 'component': BrainClientRow },
            { 'id': 'contact', 'component': BrainContactRow },
        ]
    }
}

export default siteConfig;