import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import { Button } from 'components/rows/brain/Elements.js';

export default function HeroRow(props) {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.pageYOffset > 0) setScrolled(true);
            else setScrolled(false);
        };
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <div className='relative w-full lg:min-h-screen flex flex-col items-center lg:justify-center bg-white overflow-hidden site-px pt-[120px] lg:pt-[200px] pb-[60px] lg:pb-0'>

            <div className='flex lg:hidden w-full flex-col items-center text-center gap-2'>
                <div className='font-["Amatic_SC",cursive] tracking-wide text-4xl font-bold'>At Creo brAIn We do</div>
                <div className='text-[#444242] text-[44px] leading-none font-["Patua_One",cursive] tracking-widest uppercase'>Artifical<br />Intelligence</div>
                <div className='font-["Amatic_SC",cursive] tracking-wide text-4xl font-bold'>Reseach & Developement</div>
            </div>

            <div className='relative w-full max-w-[460px] scale-100 lg:scale-[0.825] 2xl:scale-[1]'>

                <motion.img
                    className='w-full py-8 lg:py-0 hidden lg:block'
                    src='assets/images/sites/brain/hero/head.png'
                    alt='Creo brAIn'
                    transition={{ type: "spring", bounce: 0.4, delay: 0.3 }}
                    animate={{ scale: 1, opacity: 1 }}
                    initial={{ scale: 0, opacity: 0.5 }} />

                <img className='w-full py-8 lg:py-0 block lg:hidden' src='assets/images/sites/brain/hero/head.png' alt='Creo brAIn' />

                <motion.div
                    className='absolute top-0 left-0 hidden lg:flex w-full h-full'
                    transition={{ type: "spring", bounce: 0.4, delay: 1 }}
                    animate={{ scale: 1, opacity: 1 }}
                    initial={{ scale: 1.05, opacity: 0.001 }}>

                    <LineItem label='Interpret' line={'assets/images/sites/brain/hero/line-5.png'} className='top-[60px] left-[225px]' lineClassName='bottom-0 right-0' buttonWrapClassName='top-0 left-[7px]' buttonClassName='bottom-0 -left-[100px]' />
                    <LineItem label='See' line={'assets/images/sites/brain/hero/line-4.png'} className='top-[65px] left-[182px]' lineClassName='bottom-0 right-0' buttonWrapClassName='top-[0px] left-[0px]' buttonClassName='-top-[24px] right-0' />
                    <LineItem label='Listen' line={'assets/images/sites/brain/hero/line-6.png'} className='top-[50px] right-[210px]' lineClassName='bottom-0 left-0' buttonWrapClassName='top-[0px] right-[0px]' buttonClassName='-top-[24px] left-0' />
                    <LineItem label='Smell' line={'assets/images/sites/brain/hero/line-3.png'} className='top-[120px] left-[170px]' lineClassName='bottom-0 right-0' buttonWrapClassName='top-[0px] left-[0px]' buttonClassName='-top-[24px] right-0' />
                    <LineItem label='Read' line={'assets/images/sites/brain/hero/line-7.png'} className='top-[80px] right-[185px]' lineClassName='bottom-0 left-0' buttonWrapClassName='top-[0px] right-[0px]' buttonClassName='-top-[24px] left-0' />
                    <LineItem label='Sniff' line={'assets/images/sites/brain/hero/line-14.png'} className='top-[255px] right-[200px]' lineClassName='bottom-0 left-0' buttonWrapClassName='top-[0px] right-[0px]' buttonClassName='-top-[24px] left-0' />
                    <LineItem label='Hear' line={'assets/images/sites/brain/hero/line-11.png'} className='top-[185px] left-[80px]' lineClassName='bottom-0 right-0' buttonWrapClassName='top-[65px] left-[0px]' buttonClassName='-top-[24px] right-0' />

                </motion.div>

            </div>

            <div className='flex lg:hidden text-[52px] text-color-site-primary leading-none'>We do AI that WORKS!</div>

            <motion.div
                className={'hidden lg:flex absolute bottom-0 left-0 p-6 2xl:p-10 flex-row gap-2 text-3xl xl:text-4xl tracking-wide font-bold ' + (props.className || '')}
                initial={'visible'}
                animate={!scrolled ? 'visible' : 'hidden'}
                variants={{ visible: { x: 0 }, hidden: { x: '-100%' } }}>

                <div className='font-["Amatic_SC",cursive]'>We do </div>
                <div className='text-[#444242] font-["Patua_One",cursive] tracking-widest uppercase'>Artifical<br />Intelligence</div>
                <div className='font-["Amatic_SC",cursive] self-end'> Reseach & Developement</div>

            </motion.div>

            <motion.div
                className={'hidden lg:flex absolute bottom-0 right-0 p-6 2xl:p-10 text-6xl xl:text-7xl text-color-site-primary font-bold ' + (props.className || '')}
                initial={'visible'}
                animate={!scrolled ? 'visible' : 'hidden'}
                variants={{ visible: { x: 0 }, hidden: { x: '100%' } }}>We do AI that WORKS!</motion.div>

        </div>
    );
}

function LineItem(props) {
    return (
        <div className={'absolute w-0 h-0 ' + (props.className || '')}>
            <div className={'absolute w-max ' + (props.lineClassName)}>
                <img className='w-full h-full' src={props.line} alt='' />
                <div className={'absolute w-0 h-0 ' + (props.buttonWrapClassName)}>
                    <div className='relative w-full h-full'>
                        <div className={'absolute w-max h-max ' + (props.buttonClassName)}><Button label={props.label} disabled /></div>
                    </div>
                </div>
            </div>
        </div>
    );
}