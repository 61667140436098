import { Helmet } from 'react-helmet';
import 'css/brain.css';

export default function Meta(props) {
    return (
        <div className='application'>
            <Helmet>
                <title>Creo brAIn</title>

                <meta name='keywords' content='creo group, creo, creo.hu, creo.hu kft.' />
                <meta name='description' content='A Te sikered!' />
                <meta name='author' content='Creo - https://creo.hu' />

                <meta property='og:url' content='https://www.creo.hu/' />
                <meta property='og:title' content='Creo Group' />
                <meta property='og:description' content='A Te sikered!' />
                <meta property='og:site_name' content='www.creo.hu' />
                <meta property='og:image' content='https://www.creo.huassets/images/sites/brain/images/sites/og-img.png' />

                <link rel="apple-touch-icon" sizes="180x180" href="assets/images/sites/brain/favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="assets/images/sites/brain/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="assets/images/sites/brain/favicon/favicon-16x16.png" />
                <link rel="manifest" href="assets/images/sites/brain/favicon/site.webmanifest" />
                <link rel="mask-icon" href="assets/images/sites/brain/favicon/safari-pinned-tab.svg" color="#8bc43f" />
                <link rel="shortcut icon" href="assets/images/sites/brain/favicon/favicon.ico" />
                <meta name="msapplication-TileColor" content="#8bc43f" />
                <meta name="msapplication-config" content="assets/images/sites/brain/favicon/browserconfig.xml" />
                <meta name="theme-color" content="#8bc43f" />

                <link rel='preconnect' href='https://fonts.googleapis.com' />
                <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
                <link href='https://fonts.googleapis.com/css2?family=Adamina&family=Amatic+SC:wght@400;700&family=Patua+One&display=swap' rel='stylesheet' />

                <link rel='stylesheet' type='text/css' href='css/brain.css' />
            </Helmet>
        </div>
    );
}