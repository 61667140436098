import React, { useState } from 'react';

import { Box, Button, Modal } from 'components/rows/brain/Elements.js';

export default function ProductsRow(props) {
    const [modal, setModal] = useState(false);
    const [hover, setHover] = useState(false);

    const items = [
        { 'title': 'MediaBubble', 'position': { 'top': 0, 'left': 50 }, 'image': 'assets/images/sites/brain/products/portfolio_logo_mediabubble.png', 'technology': 'Semantic representation', 'url': 'http://mediabubble.hu/', 'description': 'MediaBubble is an NGO project to help news readers with extending their filter bubble. Most people rely on a single news source, which makes them easy to influence. Our project helps them by showing the article they are reading from the aspect of other news portals. Our machine learning engine constantly monitors the Hungarian online media. Based on the articles, the new topics are identified, so that the readers can be presented with all the articles appearing in the actual topic.<br/><br/>Technically speaking, MediaBubble conducts automated, realtime topic detection on news articles appearing in the online media. Topic detection, the technology core of the system relies on our state of the art, high performance semantic modelling engine. On the other hand, regarding the content delivery, dedicated crawlers have been developed to monitor the main Hungarian media portals.<br/><br/>The project has been funded by Google DNI.' },
        { 'title': 'Final Exam', 'position': { 'top': 75, 'left': 0 }, 'image': 'assets/images/sites/brain/products/portfolio_logo_zarovizsga.png', 'technology': 'Computer Vision', 'url': 'https://finalexam.hu/', 'description': 'The medical and pharmaceutical final exams are conducted test based and on paper basis in Hungary. At the end of the exams, the test sheets are collected and are scanned. The scanned images are then imported into the exam management software, which utilizes our computer vision solution to automatically evaluate the test sheets. The machine learning features are implemented on the top of our highly scalable, microservice based framework, so that the students will be informed about the results as soon as possible. The advantage of the system is that the workflow can be processed both pipeline based and also per request. The latter case is involved when a scanning problem occurs and a sheet needs to be fixed at a certain processing step.' },
        { 'title': 'Automizy', 'position': { 'top': 0, 'left': 0 }, 'image': 'assets/images/sites/brain/products/portfolio_logo_automizy.png', 'technology': 'Attention based seq2seq', 'url': 'https://automizy.com/', 'description': 'Automizy is a martech startup aiming for the U.S. market. Automizy provides B2B newsletters. The goal of the service is to guide the leads through a pre-defined journey. For example, the users can be engaged with content and can be converted into registered users at a later stage of the journey. In order to help the assembly process of the newsletters, Automizy provides a software feature to automatically generate subject lines for a particular email. Multiple subject lines can be important for A/B testing purposes. The importance of the subject line can be found in the first conversion step, as the sender and the subject of an email is the first impression for the recipient. This is the time when a user decides whether to open an email or not.' },
        { 'title': 'GroupMind', 'position': { 'top': /*40*/55, 'left': 30 }, 'image': 'assets/images/sites/brain/products/portfolio_logo_analogy.png', 'technology': 'Semantic technology', 'url': 'https://www.analogy.co/', 'description': 'GroupMind improves the consistency and the compliance of organizations. Its recommended target audience is companies with information as an asset. GroupMind improves the internal support of a company by digitizing the Q&A process with the help of our AI engine. GroupMind basically provides a knowledge base, the colleagues can look up information in. The knowledge base consists of documents prepared by the experts of the company. The advantage of GroupMind is that the users can initiate meaning based search, so that they can also paraphrase the information they need. The knowledge base is also self-maintaining and self-learning as it improves during the internal support conversations.<br/><br/>From the technical aspect, GroupMind involves our state of the art semantic technology. The semantic engine processes the documents and develops the semantic model for each paragraph of the documents. In the case a user issues a text based search query, the query is also mapped into the semantic space and the most relevant paragraphs are returned. To support corporate information management, access control is also conducted on the documents.' },
        { 'title': 'PartnerMail', 'position': { 'top': 0, 'left': 80 }, 'image': 'assets/images/sites/brain/products/portfolio_logo_pm.png', 'technology': 'Personalization', 'url': 'https://partnermail.eu/', 'description': 'PartnerMail is an email remarketing product in the electronic commerce domain. PartnerMail sends personalized emails to the customers of a webshop. The personalization means that each email contains a custom list of products. Those products, which are relevant to the user. The relevance is calculated based on the consumption behaviour of each customer, the user and product attributes and the purchase logs.<br/><br/>PartnerMail provides an API, which is to be integrated with an electronic commerce system. Based on our in-house developed recommendation engine, the shopping habit of each user is modelled and the most relevant products are selected for each user. PartnerMail also provides a user interface to define the template of the newsletter which is to be populated with the selected products. Based on our experiences, PartnerMail significantly improves the conversion rate of the promoted products.' },
    ]

    return (
        <div className='w-full bg-white flex items-center justify-center site-px pt-16 lg:pt-32'>

            <div className='w-full max-w-[960px] flex flex-col lg:flex-row items-center gap-8 lg:gap-32'>

                <Heading />

                <div className='grid grid-cols-2 gap-4 scale-[0.85] lg:scale-100 origin-left sm:origin-center bg-red-4000'>
                    {items.map((item) => (
                        <div className={'flex items-start transition-all duration-200 ' + ((hover == null || (hover != null && hover === item.title)) ? 'scale-100 rotate-1' : 'scale-95')} onMouseEnter={() => setHover(item.title)} onMouseLeave={() => setHover(null)} style={{ paddingTop: item.position.top, paddingLeft: item.position.left }}>

                            <div onMouseEnter={() => setHover(item.title)} onMouseLeave={() => setHover(null)}>
                                <Button key={item.title} label={item.title} disabled={false} onClick={() => setModal(item)} />
                            </div>

                        </div>))}
                </div>

                {modal && <ItemModal onClose={() => setModal(false)} data={modal} />}

            </div>

        </div>
    );
}

function Heading(props) {
    return (
        <div className='text-[#444242] uppercase leading-none flex-shrink-0'>
            <div className='text-[50px] font-["Patua_One",cursive] tracking-wider'>Products</div>
            <div className='text-[60px] font-["Amatic_SC",cursive] text-color-site-primary tracking-wider'>based on our</div>
            <div className='text-[38px] font-["Patua_One",cursive] tracking-[.0845em]'>Algorithms</div>
        </div>
    );
}

function ItemModal(props) {
    return (
        <Modal className='relative mt-28 mb-8 mx-4 max-w-full' onClose={props.onClose} >

            <img className='absolute bottom-0 pointer-events-none' src='assets/images/sites/brain/modal/big_dialog_bottom.png' alt='' />
            <img className='absolute top-0 pointer-events-none bg-white' src='assets/images/sites/brain/modal/big_dialog_top_2.png' alt='' />

            <div className='flex flex-col gap-6 md:gap-8 p-6 md:p-8 my-[2px] z-10 overflow-y-auto scrollbar-hide'>
                <div className='flex flex-col md:flex-row md:items-center gap-8'>
                    <Box className='hidden md:flex'>
                        <img className='w-[125px]' src={props.data.image} alt='' />
                    </Box>

                    <div className='flex-1 flex flex-col gap-2 mr-8'>
                        <div className='font-["Adamina",serif] uppercase tracking-wide text-3xl md:text-5xl'>{props.data.title}</div>
                        <div className='text-xl md:text-[32px] font-bold font-["Amatic_SC",cursive] tracking-wide'>Technology: {props.data.technology}</div>
                    </div>
                    <ModalButtons className='hidden md:flex' data={props.data} onClose={() => props.onClose()} />
                </div>
                <div className='font-["Adamina",serif] text-sm md:text-base leading-7' dangerouslySetInnerHTML={{ __html: props.data.description }} />
                <ModalButtons className='flex md:hidden' data={props.data} onClose={() => props.onClose()} />
            </div>

        </Modal>
    )
}

function ModalButtons(props) {
    return (
        <div className={'flex flex-col md:flex-col gap-4 ' + (props.className || '')}>
            <Button label='Visit' disabled={false} onClick={() => window.open(props.data.url, '_blank')} />
            <Button label='Close' disabled={false} onClick={() => props.onClose()} />
        </div>
    );
}