import { Box } from 'components/rows/brain/Elements.js';

export default function ServicesRow(props) {
    const items = [
        { 'title': 'Exploratory data analysis', 'image': 'assets/images/sites/brain/services/analysis.jpg', 'description': 'Help analyze the data asset', 'className': '-rotate-[0.5deg]' },
        { 'title': 'Consultancy', 'image': 'assets/images/sites/brain/services/consultacy.jpg', 'description': 'Help generate novel product ideas or improvements', 'className': 'rotate-[0.5deg]' },
        { 'title': 'Database design', 'subTitle': 'annotation tasks', 'image': 'assets/images/sites/brain/services/db.jpg', 'description': 'Transform unstructured data into structured databases for learning', 'className': '-rotate-[0.5deg]' },
        { 'title': 'Machine learning research', 'subTitle': 'algorithm development', 'image': 'assets/images/sites/brain/services/mi.jpg', 'description': 'Create prototype solutions, pilots, proof of concepts', 'className': 'rotate-[0.5deg]' },
        { 'title': 'Production grade Implementation', 'image': 'assets/images/sites/brain/services/production.jpg', 'description': 'Optimize prototype solutions', 'className': '-rotate-[0.5deg]' },
        { 'title': 'Software Development', 'image': 'assets/images/sites/brain/services/development.jpg', 'description': 'Help to integrate solutions into existing frameworks', 'className': 'rotate-[0.5deg]' },
        { 'title': 'Support', 'image': 'assets/images/sites/brain/services/support.jpg', 'description': 'Maintain services based on our solutions', 'className': '-rotate-[0.5deg]' },
    ]

    return (
        <div className='w-full bg-white flex items-center justify-center site-px pt-16 lg:pt-32'>

            <div className='w-full max-w-boxed flex flex-col items-center gap-16'>

                <Heading />

                <div className='w-full flex flex-col items-center gap-12 sm:gap-10'>
                    {items.map((item, index) => (<Item key={index} data={item} index={index} />))}
                </div>

            </div>

        </div>
    );
}

function Heading(props) {
    return (
        <div className='flex flex-col md:flex-row gap-4 md:gap-16 justify-center'>

            <div className='hidden md:block w-[249px] h-[168px] relative flex-shrink-0 mt-[20px] rotate-1'>
                <img className='absolute w-[249px] h-[168px]' src='assets/images/sites/brain/services/portfolio_arrow.png' alt='' />
                <img className='absolute w-[249px] h-[168px]' src='assets/images/sites/brain/services/portfolio_arrow_spring.png' alt='' />
                <div className='absolute text-[#444242] text-[60px] font-bold font-["Amatic_SC",cursive] tracking-wide top-[38px] left-[35px]'>Services</div>
            </div>

            <div className='block md:hidden heading text-center'>Services</div>

            <div className='flex flex-col text-center md:text-left'>
                <div className='block md:hidden font-["Adamina",serif] text-[14px] tracking-wide'>It is quite rare that someone already has a perfect, detailed plan to follow. We are happy to be part of all stages from generating ideas to deploying a service or making the first products.</div>
                <div className='hidden md:block text-[22px] font-["Patua_One",cursive] uppercase'>It is quite rare that someone already has a perfect, detailed plan to follow.</div>
                <div className='hidden md:block font-["Adamina",serif] text-[16px] tracking-wide'>We are happy to be part of all stages from generating ideas to deploying a service or making the first products.</div>
                <div className='text-[#444242] text-[40px] md:text-[62px] font-bold font-["Amatic_SC",cursive] tracking-wide text-color-site-primary leading-none mt-4 md:rotate-[0.2deg]'>Our typically offered services are listed below.</div>
            </div>

        </div>
    );
}

function Item(props) {
    return (
        <div className={'flex flex-col sm:flex-row gap-8 sm:gap-10 items-center text-center ' + (props.data.className || '')}>
            <Box image={props.data.image} className={props.index % 2 === 0 ? 'order-2 sm:order-1' : 'order-2 sm:order-2'} />
            <ItemText data={props.data} className={props.index % 2 === 0 ? 'sm:order-2' : 'sm:order-1 sm:text-right'} />
        </div>
    );
}

function ItemText(props) {
    return (
        <div className={'flex flex-col leading-none ' + (props.className || '')}>
            <div className='text-[28px] sm:text-[32px] font-["Patua_One",cursive] tracking-wider uppercase'>{props.data.title}</div>
            {props.data.subTitle && <div className='text-[20px] sm:text-[32px] font-["Patua_One",cursive] tracking-wider uppercase'>{props.data.subTitle}</div>}
            <div className='text-[18px] sm:text-[26px] font-["Amatic_SC",cursive] font-bold  tracking-wider mt-1'>{props.data.description}</div>
        </div>
    );
}